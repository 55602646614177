.container {
    margin: 60px 0px;
    background-color: #E8E7F1;
    padding: 60px;
    justify-content: space-between;
    display: flex;
    gap: 4%;
}

.right {
    width: 41%;
}

.right img {
    width: 100%;
}

.left {
    width: 55%;
}

.left h2 {
    font-family: Quicksand;
    font-size: 66px;
    font-weight: 600;
    line-height: 79px;
    letter-spacing: 0em;
    text-align: left;
    color: #312584;
    padding-bottom: 10px;
}

.left p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: justify;
}

hr {
    border: 1px solid #312584;
    margin: 30px 0px;
    /* width: 100%; */
}

@media (max-width: 1125px) {
    .left h2 {
        font-size: 60px;
        line-height: 74px;
    }

    .left p {
        font-size: 19px;
        line-height: 28px;
    }
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        padding: 60px 30px;
    }

    .left,
    .right {
        width: 100%;
    }

    .left h2 {
        font-size: 34px;
        line-height: 48px;
        padding-bottom: 0px;
        padding-top: 10px;
    }

    .left p {
        font-size: 16px;
        line-height: 28px;
    }
}

@media (max-width: 468px) {
    .left h2 {
        font-size: 26px;
        line-height: 32px;
    }

    .left p {
        font-size: 14px;
        line-height: 26px;
    }
}

@media (min-width: 1279px) and (max-width: 1440px) {
    .left h2 {
        font-size: 48px;
        line-height: 60px;
    }

    .left p {
        font-size: 16px;
        line-height: 28px;
    }
}