.container {
    width: 100%;
    position: relative;

}

.footer1 {

    padding-top: 180px;
    display: flex;
    justify-content: space-between;



}

.headings {
    color: #F09303;
    font-size: 42px;
    font-weight: 700;
    line-height: 50px;
    font-family: Quicksand;
    margin-bottom: 0px;

}

.taglink {
    color: #D9D9D9;
    font-size: 22px;
    font-weight: 500;
    line-height: 45px;
    font-family: Quicksand;
    margin-bottom: 0px;


}

.links_col1 {
    width: 10%;

}

.links_col2 {
    width: 28%;
}

.links_col3 {
    width: 26%;
}

.cols {
    display: flex;
}

.line {
    border: none;
    /* Remove default border */
    height: 2px;
    /* Adjust the height of the line */
    width: 40px;
    /* Adjust the width of the line */
    background-color: white;

}

.row {
    gap: 8px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.links_col3 .row1 {
    font-size: 18px;
    line-height: 28px;
    color: #A5B9CC;
    word-wrap: break-word;
}

.links_col3 .row1:hover {
    color: #FFFFFF;
}

.row1 svg {
    margin-right: 20px;
}

.row1 {
    color: #A5B9CC;
}

.row1 :hover {
    color: #FFFFFF;
}

.links_col1 h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 38px;
    color: white;
    margin-bottom: 0px;
}

.links_col2 h2 {
    font-size: 20px;
    font-weight:500;
    line-height: 38px;
    color: white;
    margin-bottom: 0px;
}

.links_col3 h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 38px;
    color: white;
    margin-bottom: 0px;
}

.bg {
    width: 92%;
    background-image: url('../../../public/assets/images/Maskgroup145.png');
    background-size: cover;
    margin: auto;
    background-position: right;
    height: 456px;
    z-index: 10;
    position: absolute;
    top: 0;
    border-radius: 14px;
    overflow: hidden;
    left: 4%;
}

.highlighted {
    width: 60%;
    padding: 60px;
    height: 100%;
    background: linear-gradient(90deg, #000000 -13.93%, rgba(217, 217, 217, 0) 37.79%);
}

.highlighted h2 {
    font-family: Quicksand;
    font-size: 42px;
    font-weight: 500;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}

.buttoncont {
    display: flex;
    gap: 30px;
    padding-top: 100px;
}

.buttoncont a {
    text-decoration: none;
}

.highlighted button {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 11px 20px;
    border-radius: 50px;
    width: unset;
    text-align: center;
    color: white;
    border: 1px solid white;
    background-color: transparent;
    cursor: pointer;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 37px;
    width: 190px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.buttoncont img {
    width: 100%;
    transition: transform 0.3s ease;
}

.buttoncont img:hover {
    transform: scale(1.1);
}

.links {
    display: flex;
    flex-direction: column;
    display: inline-block;
}

.footer {
    background-color: #312584;
    margin-top: -65px;
    padding: 40px 40px 0px 40px;
    z-index: 0;
    display: flex;
    gap: 40px;
    flex-direction: column;
    align-items: baseline;
    position: absolute;
    top: 370px;
    justify-content: flex-end;
    left: 0;
    right: 0;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 15px;
    width: 26%;
    gap: 10px;
    /* border-bottom: 1px solid #74B32D; */
}

.logo img {
    width: 124px;
    height: auto;
}

.logo p {
    font-size: 16px;
    color: #A5B9CC;
}

.links {
    text-decoration: none;
    color: #A5B9CC;
    ;
    font-size: 16px;
    display: inline-block;
}

.links:hover {
    color: #FFFFFF;
}

.texts {
    font-family: Jost;
    font-size: 20px;
    font-weight: 400;
    color: white;
    line-height: 20px;
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 28px 0px;
    border-top: 1px solid #74B32D;
}

.icons {
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: 1440px) {
    .stickybar {
        display: none;
        z-index: 5;
    }

    .headings {
        color: #F09303;
        font-size: 38px;
        font-weight: 700;
        line-height: 50px;
        font-family: Quicksand;
        margin-bottom: 0px;
    }

    .taglink {
        color: #D9D9D9;
        font-size: 23px;
        font-weight: 500;
        line-height: 35px;
        font-family: Quicksand;
        margin-bottom: 0px;

    }
}

@media (max-width: 928px) {
    .stickybar {
        display: none;
    }

    .highlighted {
        width: 100%;
        background-color: rgba(46, 40, 40, 0.5);
    }

    .footer {
        top: 420px;
    }

    .links {
        display: inline-block;
    }

    .headings {
        color: #F09303;
        font-size: 38px;
        font-weight: 700;
        line-height: 50px;
        font-family: Quicksand;
        margin-bottom: 0px;
    }

    .taglink {
        color: #D9D9D9;
        font-size: 28px;
        font-weight: 500;
        line-height: 35px;
        font-family: Quicksand;
        margin-bottom: 0px;

    }

}

@media (max-width: 428px) {
    .stickybar {
        background-color: #312584;
        height: 100px;
        display: flex;
        gap: 20px;
        justify-content: space-around;
        align-items: center;
    }

    .container {
        display: none;
    }

    .bg {
        /*  width: 85%; */
        height: 300px;
    }

    .footer {
        top: 260px;
    }

    .links {
        display: inline-block;
    }
}

@media (max-width: 768px) {


    .cols {
        flex-direction: column;
    }

    .links {
        padding: 0px 0px !important;
        display: inline-block;
    }

    .footer1 {
        display: flex;
        flex-direction: column;
        padding: 120px 20px 0px 20px !important;
        gap: 20px;
        width: 100%;
    }

    .logo {
        width: 100%;
    }

    .logo img {
        width: 140px !important;
        margin-top: 0px !important;
    }

    .links_col1 {
        width: 100%;
    }

    .links_col2 {
        width: 100%;
    }

    .links_col3 {
        width: 100%;
    }

    .bg {
        height: 400px !important;
    }

    .footer {
        height: fit-content;
        padding: 40px 0px 0px 0px;
        top: 360px;
    }

    .highlighted h2 {
        line-height: 32px;
        font-size: 26px;
    }

    .highlighted button {
        background-color: #F09303;
        padding: 7px 22px;
        font-size: 12px;
        border-color: #F09303;
        width: 123px;
        line-height: 17px;
    }

    .highlighted {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 35px;
    }

    .logo {
        flex-direction: column;
        align-items: flex-start;
    }

    .logo img {
        width: 225px;
        /*  margin-left: -30px; */
        margin-top: 150px;
    }

    .links {
        padding: 10px 35px;
        display: block;
    }

    .content {
        flex-direction: column-reverse;
        gap: 15px;
        text-align: center;
    }

    .icons {
        top: -50px;
        position: absolute;
    }

    .headings {
        color: #F09303;
        font-size: 35px;
        font-weight: 700;
        line-height: 40px;
        font-family: Quicksand;
        margin-bottom: 0px;
    }

    .taglink {
        color: #D9D9D9;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        font-family: Quicksand;
        margin-bottom: 0px;

    }

}

@media (min-width: 1279px) and (max-width: 1440px) {
    .stickybar {
        display: none;
    }

    .footer {
        padding: 40px 40px 0px 40px;
    }

    .links_col2 {
        width: 24%;
    }

    .links_col3 {
        width: 28%;
    }
}

@media (max-width: 768px) {
    .links {
        display: inline-block;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .stickybar {
        display: none;
    }

    .headings {
        color: #F09303;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        font-family: Quicksand;
        margin-bottom: 0px;
    }

    .taglink {
        color: #D9D9D9;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        font-family: Quicksand;
        margin-bottom: 0px;

    }
}



@media (max-width: 480px) {
    .stickybar {
        background-color: #312584;
        height: 60px;
        display: flex;
        gap: 0px;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0px 40px;
        text-align: center;
    }
    .stickybar img{
        width: 56%;
    }
    .container {
        display: none;
    }
}


@media (min-width: 768px) and (max-width: 1823px) {
    .stickybar {
        display: none;
    }
}