.container {
    width: 65%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px;
}

.container h2 {
    font-family: Quicksand;
    font-size: 50px;
    font-weight: 600;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: center;
    color: #312584;
    padding: 15px 0px;
}

.title {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #393939;
    padding: 20px;
}

.box {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 15px 0px 10px 0;
    cursor: pointer;
}

.question {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #393939;
}

.answer {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: justify;
    color: #393939;
    padding: 15px 55px 18px 0px;
    border-bottom: 1px solid #999999;
}

.aligned {
    width: 100%;
    border-bottom: 1px solid #999999;
    margin-top: 20px;
}

p {
    margin-bottom: 0px;
}

@media (max-width: 1078px) {
    .container {
        width: 85%;
    }

    .box svg {
        width: 20px;
    }

    .question {
        font-size: 20px;
    }

    .answer {
        font-size: 18px;
    }

    .container h2 {
        font-size: 36px;
        line-height: normal;
    }
}

@media (max-width: 578px) {
    .container h2 {
        font-size: 26px;
        /* margin-bottom: 0px; */
        line-height: normal;
    }

    .title,
    .question {
        font-size: 14px;
        line-height: normal;
    }

    .title {
        padding: 0px 10px;
    }

    .answer {
        font-size: 12px;
        line-height: normal;
        padding: 10px 30px 10px 0px;
    }

    .aligned {
        margin-top: 7px;
    }

    .container {
        padding: 30px 20px;
        width: 100%;
    }

    .box {
        margin-top: 10px;
        margin-bottom: 6px;
    }

    .box svg {
        width: 13px;
    }
}

@media (max-width: 480px) {
    .container {
        padding-bottom: 80px;
    }
}