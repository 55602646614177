.spacing{
    height: 160px;
    width: 50px;
}

@media  (max-width: 768px){
    .spacing{
       display: none;
    }
    
}