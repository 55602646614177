.container {
    width: 100%;
    display: block;

}

.left {
    width: 705px;
    clip-path: polygon(0 0, 97% 0, 100% 100%, 0% 100%);

}

.right {
    width: calc(100% - 705px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    gap: 18px;

}

.right a {
    font-size: 18px;
    color: #696969;
}

.content {
    align-items: center;
    padding: 10px 24px;
    display: flex;
    color: white;
    gap: 20px;
    font-size: 16px;
}

.content a {
    text-decoration: none;
    font-weight: 600;
    color: white;
    padding-left: 6px;
}

.mainHeader {
    margin: auto;
    width: 90%;
    height: 99px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 4;
}

.mainHeader img {
    padding: 4px;
    width: 171px;
    height: auto;
}

.connect {
    padding: 15px 32px;
    color: white;
    background-color: #F09303;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 24px; */
    border: none;
    width: fit-content;
    transition: 0.3s ease-in;
    margin-left: 20px;
}

.connect:hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: #F09303;
    border: 1px solid #F09303;
}

.navmenu {
    transition: 0.3s ease-in;
    color: #1F1F1F;
    /* font-family: Poppins; */
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    padding: 10px;

}

.navmenu:hover,
.service:hover {
    color: #F09303;
}

.rightNav {
    display: inline-flex;
    /* margin-left: 150px; */
    transition: all ease-in 0.6s;
    gap: 40px;
    z-index: 1;
}

.mainHeader svg {
    display: none;
}

.dropdown {
    display: inline-block !important;
    margin-left: 10px;
    margin-top: 2px;
}

.page {
    position: relative;
}

.list {
    display: none;
    position: absolute;
    width: 240px;
   
    background-color: #ffffff;
    justify-content: flex-end;
    margin-top: 8px;
    padding: 10px;
   
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.service {
    color: black;
    font-weight: 600;
    transition: 0.3s ease-in;
    text-decoration: none;
    font-size: 14px;
    padding: 5px;
    text-transform: capitalize;
}

.pages:hover .list {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .navmenu {
        font-size: 13px;
        margin-left: 0px;
    }

    .connect {
        font-size: 16px;
        padding: 15px 26px;
        margin-left: 10px;
    }

    .rightNav {
        gap: 0px;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .navmenu {
        font-size: 16px;
    }

    .connect {
        font-size: 16px;
        padding: 15px 26px;
        margin-left: 10px;
    }

}

@media (max-width: 768px) {
    .dropdown {
        margin-left: 70px;
    }

    .rightNav {
        margin-left: 30px;
        flex-direction: column;
        position: absolute;
        background: white;
        top: 99px;
        height: 125vh;
        left: -100%;
        gap: 0px;
    }

    .rightNav a {
        margin-left: 10px;
    }

    .navmenu a {
        margin-left: 0px;
    }

    .opened {
        left: -30px !important;
    }

    .mainHeader {
        width: 100%;
        height: 106px;
        justify-content: flex-end;
        gap: 118px;
    }

    .navmenu {
        font-size: 17px;
        width: 233px;
    }

    .connect {
        margin-right: 50px;
        padding: 15px 23px;
    }

    .mainHeader svg {
        display: block;
        position: absolute;
        left: 0px;
        width: 78px;
    }

    .mainHeader img {
        margin-left: 0%;
        width: 180px;
    }

    .list {
        width: 200px;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width:600px) {
    .mainHeader {
        height: 106px;
        justify-content: flex-end;
        gap: 60px;
    }

    .navmenu {
        font-size: 15px;
    }

    .connect {
        padding: 10px 16px;
        margin-right: 15px;
        font-size: 11px;
    }

    .mainHeader img {
        margin-left: 0%;
        width: 106px;
    }
}

@media (max-width: 420px) {
    .connect {
        padding: 10px 16px;
        margin-right: 15px;
    }

    .mainHeader {
        gap: 2px;
    }

    .mainHeader img {
        /* margin-left: 40%; */
        width: 106px;

    }
}

@media (min-width: 1279px) and (max-width: 1440px) {

    .mainHeader {
        height: 82px;
    }

    .mainHeader img {
        width: 140px;
    }

    .navmenu {
        font-size: 18px;
    }
}