.container{
    background-color: #E8E7F1;
    /* width: 90%; */
    padding: 60px 40px 80px 40px;
    border-radius: 10px;
    margin: 60px 4%;
  }
  .spekars{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-around;
  }
  .one{
    height: 80%;
    width: 25%;
  }
  .one img{
    width: 100%;
  }
  .spekars h3{
    font-size: 42px;
    font-weight: 600;
    color: #312584;
    text-align: center;
    font-family: "Quicksand", sans-serif;
  }
  .spekars span{
    font-weight: 400;
    text-align: center;
    font-family: "Quicksand", sans-serif;
  }
  .two{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .spekars button{
    border: 4px solid #F09303;
    background-color:#E8E7F1;
    color: #312584;
    height: 55px;
    width: 213px;
    margin-top: 10px;
  }
  @media (max-width: 928px) {
    .spekars button{
      height: unset;
      width: unset;
      padding: 7px 15px;
      font-size: 18px;
    }
    .spekars{
      gap: 2px;
    }
    .container{
      padding: 60px 80px 80px 80px;
      margin: 0px 4% 60px 4%;
    }
  }
  @media (max-width: 768px) {
    .spekars{
      gap: 24px;
    }
    .spekars h3{
      font-size: 22px;
      line-height: 34px;
    }
  .container {
    height: fit-content;
    margin: 60px 40px;
    padding: 30px 20px;
    /* width: 85%; */
  }
}
@media (max-width: 598px){
    .hidden{
        display: none;
    }
    .spekars{
        flex-direction: column;
    }
    .spekars button{
        font-size: 12px;
        padding: 14px 24px;
    }
 
    .one{
        height: 100%;
        width: 80%;
      }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .container{
    padding: 60px 80px 80px 80px;
    margin: 0px 4% 60px 4%;
  }
}

@media (min-width: 1279px) and (max-width: 1440px) {

  .spekars h3{
    font-size: 48px;
    line-height: 68px;
  }
  .two{
    width: 80%;
  }
  .container{
    padding: 60px 80px 80px 80px;
    margin: 0px 4% 60px 4%;
  }
  .spekars button{
    font-size: 24px;
    width: 320px;
    height: 80px;
  }
}