.container {
    width: 90%;
    margin: auto;
    padding: 80px 0 60px 0;
}

.container h2 {
    font-family: Quicksand;
    font-size: 62px;
    font-weight: 600;
    line-height: 73px;
    letter-spacing: 0em;
    text-align: center;
    color: #312584;
    width: 85%;
    margin: auto;
    padding-bottom: 35px;
}

.container img {
    width: 100%;
    height: auto;
}

.container p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: justify;
    color: black;
    width: 85%;
    margin: auto;
    padding-top: 15px;
}

@media (max-width: 1065px) {
    .container h2 {
        font-size: 54px;
        line-height: 64px;
    }
}

@media (max-width: 825px) {
    .container h2 {
        font-size: 44px;
        line-height: 54px;
    }

    .container p {
        font-size: 18px;
        padding-top: 10px;
    }
}

@media (max-width: 525px) {

    .container p,
    .container h2 {
        width: 100%;
        text-align: left;
    }

    .container p {
        font-size: 14px;
        line-height: 24px;
        padding-top: 0px;
        text-align: justify;
    }

    .container h2 {
        font-size: 25px;
        line-height: 30px;
        padding-bottom: 15px;
    }

    .container {
        padding: 30px 0px;
        width: 94%;
    }
}

@media (max-width: 768px) {
    .container h2 {
        font-size: 26px;
        line-height: 38px;
    }

    .container {
        padding: 80px 0 0px 0;
    }
}

@media (min-width: 1279px) and (max-width: 1440px) {
    .container {
        padding: 60px 0 60px;
    }

    .container h2 {
        font-size: 52px;
        line-height: 64px;
    }

    .container p {}
}