.container{
    height: 450px;
    width: 100%;
    /* background: url('../../../public/assets/images/Maskgroup4324.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.content{
    background: linear-gradient(270deg, rgba(49, 37, 132, 0) 38.09%, #312584 88.75%);
    height: 100%;
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 7%;
}
.content p{
  /*   width: 55%; */
}
.headline{
    font-family: Quicksand;
font-size: 24px;
font-weight: 500;
line-height: 2px;
letter-spacing: 0em;
text-align: left;
color: white;
}
.highlights{
    font-family: Quicksand;
    font-size: 52px;
    font-weight: 600;
    line-height: 68px;
    letter-spacing: 0em;
    text-align: left;
    color: #F09303;
}
.heading{
    font-family: Poppins;
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    padding-bottom: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #F09303;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.title{
    font-family: Quicksand;
font-size: 72px;
font-weight: 600;

letter-spacing: 0em;
text-align: left;
padding: 40px 0px 0px 0px;

}
.tagh1{
    font-family: Quicksand;
font-size: 32px;
font-weight: 600;

letter-spacing: 0em;
text-align: left;


}
.subtitle{
    font-family: Outfit;
font-size: 24px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
}



@media (min-width: 1279px) and (max-width: 1440px) {
    .container{
        height: 320px;
    }
    .headline{
        font-family: Quicksand;
    font-size: 24px;
    font-weight: 500;
    line-height: 2px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    }
    .highlights{
        font-family: Quicksand;
        font-size: 52px;
        font-weight: 600;
        line-height: 68px;
        letter-spacing: 0em;
        text-align: left;
        color: #F09303;
    }
    .heading{
        font-family: Poppins;
        font-size: 36px;
        font-weight: 700;
        line-height: 43.2px;
        padding-bottom: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #F09303;
        text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    }
    .title{
        font-family: Quicksand;
    font-size: 72px;
    font-weight: 600;
   
    letter-spacing: 0em;
    text-align: left;
    padding: 40px 0px 0px 0px;
    
    }
    .tagh1{
        font-family: Quicksand;
    font-size: 32px;
    font-weight: 600;
    
    letter-spacing: 0em;
    text-align: left;
   
    
    }
    
}
@media  (max-width: 1279px){
    .title{
        font-family: Quicksand;
    font-size: 72px;
    font-weight: 600;
    
    letter-spacing: 0em;
    text-align: left;
    padding: 40px 0px 0px 0px;
    
    }
    .tagh1{
        font-family: Quicksand;
    font-size: 32px;
    font-weight: 600;
   
    letter-spacing: 0em;
    text-align: left;
   
    
    }
}
@media (max-width: 1018px) {
    .content p{
        width: 85%;
    }
    .content{
        padding-left: 5%;
      }  .headline{
            font-family: Quicksand;
        font-size: 20px;
        font-weight: 500;
        line-height: 2px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        }
        .highlights{
            font-family: Quicksand;
            font-size: 52px;
            font-weight: 600;
            line-height: 68px;
            letter-spacing: 0em;
            text-align: left;
            color: #F09303;
        }
        .heading{
            font-family: Poppins;
            font-size: 30px;
            font-weight: 700;
            line-height: 40.2px;
            padding-bottom: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #F09303;
            text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
        }
        .title{
            font-family: Quicksand;
        font-size: 70px;
        font-weight: 600;
     
        letter-spacing: 0em;
        text-align: left;
        padding: 40px 0px 0px 0px;
        
        }
         .tagh1{
        font-family: Quicksand;
    font-size: 32px;
    font-weight: 600;
   
    letter-spacing: 0em;
    text-align: left;
   
    
    }
}

@media  (max-width: 768px){
    .container{
        height: 320px;
    }
    .content{
        gap: 0px;
    }
    .title{
        font-family: Quicksand;
    font-size: 32px;
    font-weight: 600;
    
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 0px 0px 0px;
    
    }
    .tagh1{
        font-family: Quicksand;
    font-size: 18px;
    font-weight: 600;
   
    letter-spacing: 0em;
    text-align: left;
   
    
    }
    
    .headline{
        font-size: 16px;
        line-height: 40px;
        width: 90% !important;
    }
    .heading{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 0px;
        padding-bottom: 0px;
        letter-spacing: 0em;
        text-align: left;
        color: #F09303;
        text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    }
}